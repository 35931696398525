import React, { useState, useCallback } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const RevenueProjectionChart = () => {
  const [activeTooltip, setActiveTooltip] = useState(null);

  const totalListings = 522000;
  const monthlySubscriptionPrice = 39;
  
  const subscriptionDurations = [0,3, 3.5, 4, 6, 12];
  const penetrationRates = [0, 0.03, 0.075, 0.09, 0.105, 0.12];
  const lowPenetrationRate = penetrationRates[0] * 100;
  const highPenetrationRate = penetrationRates[penetrationRates.length - 1] * 100;

  const calculateYearlyRevenue = (penetrationRate, subscriptionDuration) => {
    const subscribersPerYear = totalListings * penetrationRate;
    const averageMonthlySubscribers = subscribersPerYear * (subscriptionDuration / 12);
    return Math.round(averageMonthlySubscribers * monthlySubscriptionPrice * 12);
  };

  const data = penetrationRates.map((rate, index) => ({
    year: `Year ${index + 1}`,
    revenue: calculateYearlyRevenue(rate, subscriptionDurations[index]),
    penetrationRate: rate,
    subscriptionDuration: subscriptionDurations[index],
    subscribers: Math.round(totalListings * rate)
  }));

  const formatYAxis = (value) => {
    return `$${(value / 1_000_000).toFixed(0)}M`;
  };

  const customTooltip = ({ active, payload, label }) => {
    if (!active || !payload || !payload.length) return null;

    const { year, revenue, penetrationRate, subscriptionDuration, subscribers } = payload[0].payload;
    const yearNumber = parseInt(year.split(' ')[1]);

    const stories = [
      {
        title: "Laying the Groundwork",
        points: [
          "Deals with MLS typically take up to 18 months",
          "Rigorous requirements, audits, and compliance testing",
          `${(penetrationRate * 100).toFixed(1)}% initial penetration due to long sales cycle`,
          `${subscriptionDuration} month average subscription duration as early adopters test the waters`
        ]
      },
      {
        title: "Launch and Early Traction",
        points: [
          `Listing concierge launched, reaching ${subscribers.toLocaleString()} listings subscribed`,
          `Penetration increases to ${(penetrationRate * 100).toFixed(1)}% of listings`,
          `Average subscription duration extends to ${subscriptionDuration} months (approx. the time it takes to close a deal with some padding)`,
          `Revenue grows to $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Gaining Momentum",
        points: [
          `Subscriber base expands to ${subscribers.toLocaleString()} listings`,
          `Penetration rate climbs to ${(penetrationRate * 100).toFixed(1)}%`,
          `Agents see that Oppy concierge helps them keep in touch with their buyer post-transaction`,
          `Average subscription length increases to ${subscriptionDuration} months as`,
          `Revenue reaches $${revenue.toLocaleString()}`
        ]
      },
      {
        title: "Accelerated Growth",
        points: [
          `Strong marketing and word-of-mouth drives adoption to ${subscribers.toLocaleString()} listings subscribed`,
          `Total listing penetration hits ${(penetrationRate * 100).toFixed(1)}%`,
          `Subscription duration extends to ${subscriptionDuration} months. Trend continues. Home buyers see more value of having Oppy as a home concierge`,
          `Revenue surges to $${revenue.toLocaleString()} as more agents rely on Oppy for every listing`
        ]
      },
      {
        title: "Home Owner Retention",
        points: [
          `Oppy achieves ${(penetrationRate * 100).toFixed(1)}% penetration`,
          `${subscribers.toLocaleString()} active listings subscribed`,
          `Average subscription continues to grow to ${subscriptionDuration} months. Home owners have option to take over subscription from agent.`,
          `Annual revenue reaches $${revenue.toLocaleString()}`
        ]
      }
    ];

    // Add this check
    if (isNaN(yearNumber) || yearNumber < 1 || yearNumber > stories.length) {
      return null;
    }

    const story = stories[yearNumber - 1];

    // Add this check
    if (!story) {
      return null;
    }

    const avatarStyle = {
      backgroundColor: '#4CAF50',
      color: 'white',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 'bold',
      marginRight: '10px'
    };

    return (
      <div style={{ backgroundColor: '#1E1E1E', padding: '15px', borderRadius: '8px', boxShadow: '0 4px 6px rgba(0,0,0,0.1)', maxWidth: '350px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <div style={avatarStyle}>Y{yearNumber}</div>
          <h3 style={{ margin: 0, color: '#E0E0E0', fontSize: '18px' }}>{story.title}</h3>
        </div>
        <ul style={{ listStyle: 'none', padding: 0, margin: '0 0 10px 0', color: '#B0B0B0' }}>
          {story.points.map((point, index) => (
            <li key={`${point}-${index}`} style={{ marginBottom: '5px' }}>• {point}</li>
          ))}
        </ul>
        <div style={{ borderTop: '1px solid #333', paddingTop: '10px', color: '#D0D0D0' }}>
          <p style={{ margin: '5px 0' }}>📈 Revenue: <strong>${revenue.toLocaleString()}</strong></p>
          <p style={{ margin: '5px 0' }}>🎯 Penetration: <strong>{(penetrationRate * 100).toFixed(1)}%</strong></p>
          <p style={{ margin: '5px 0' }}>⏳ Avg. Subscription: <strong>{subscriptionDuration} months</strong></p>
          <p style={{ margin: '5px 0' }}>👥 Subscribers: <strong>{subscribers.toLocaleString()}</strong></p>
        </div>
      </div>
    );
  };

  const handleMouseMove = (props) => {
    if (props.activeTooltipIndex !== undefined) {
      setActiveTooltip(props.activeTooltipIndex);
    }
  };

  return (
    <div style={{ backgroundColor: 'black', padding: '2rem', borderRadius: '0.5rem', color: 'white' }}>
      <h2 className="text-3xl font-bold mb-6 text-white text-center">Oppy X Bright MLS 24/7 AI Listing Concierge</h2>
      <h3 className="text-xl font-semibold mb-4 text-white text-center">5-Year Revenue Projection (Monthly Subscriptions)</h3>
      <div className="mb-8">
        <ResponsiveContainer width="100%" height={500}>
          <LineChart 
            data={data} 
            margin={{ top: 20, right: 300, left: 300, bottom: 5 }}
            onMouseMove={handleMouseMove}
          >
            <CartesianGrid strokeDasharray="3 3" stroke="#333" />
            <XAxis dataKey="year" stroke="#fff" />
            <YAxis 
              stroke="#fff" 
              tickFormatter={formatYAxis}
              domain={[0, 'dataMax']}
              allowDataOverflow={true}
            />
            <Tooltip content={customTooltip} isAnimationActive={false} />
            <Legend wrapperStyle={{ color: '#fff' }} />
            <Line 
              type="monotone" 
              dataKey="revenue" 
              name="Projected Revenue" 
              stroke="#fff" 
              activeDot={{ r: 8 }} 
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <div className="text-sm">
        <h4 className="font-semibold mb-2">Projection Details:</h4>
        <table style={{ width: '100%', marginBottom: '1rem' }}>
          <thead>
            <tr style={{ backgroundColor: '#1f2937' }}>
              <th style={{ padding: '0.5rem 1rem', textAlign: 'left' }}>Year</th>
              <th style={{ padding: '0.5rem 1rem', textAlign: 'left' }}>Penetration Rate</th>
              <th style={{ padding: '0.5rem 1rem', textAlign: 'left' }}>Avg. Subscription Duration (months)</th>
              <th style={{ padding: '0.5rem 1rem', textAlign: 'left' }}>Projected Revenue</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr 
                key={`${item.year}-${index}`}
                style={{
                  backgroundColor: item.year === data[activeTooltip]?.year ? '#2563eb' : index % 2 === 0 ? '#111827' : '#1f2937'
                }}
              >
                <td style={{ padding: '0.5rem 1rem' }}>{item.year}</td>
                <td style={{ padding: '0.5rem 1rem' }}>
                  {(item.penetrationRate * 100).toFixed(1)}% 
                  <br></br>
                  <sub style={{ opacity: 0.6 }}>({item.penetrationRate} * {totalListings.toLocaleString()} = {item.subscribers.toLocaleString()} subscribers)</sub>
                </td>
                <td style={{ padding: '0.5rem 1rem' }}>{item.subscriptionDuration}</td>
                <td style={{ padding: '0.5rem 1rem' }}>${item.revenue.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p className="font-semibld mb-2">Proposed Integration:</p>
        <ul className="list-disc pl-5 mt-2 space-y-1 mb-4">
          <li>Each agent must list their property through the MLS</li>
          <li>At the point of listing, the agent will be given the option to add the AI concierge</li>
          <li>The agent will be presented with a pre-trained Oppy listing concierge they can customize</li>
          <li>The agent will be able to try it, secure a phone number, get a qr code and purchase a monthly subscription in a few clicks</li>
        </ul>
        <p className="font-semibold mb-2">Assumptions:</p>
        <ul className="list-disc pl-5 mt-2 space-y-1 mb-4">
          <li>Total market size: {totalListings.toLocaleString()} listings</li>
          <li>Monthly subscription price: ${monthlySubscriptionPrice}</li>
          <li>Average subscription duration: {subscriptionDurations.join(', ')} months
            <ul>
              <li>We believe duration can be extended by making this Oppy a home concierge the home buyer wants to keep after the transaction closes</li>
            </ul>
          </li>
          <li>Penetration rates increase yearly from {lowPenetrationRate.toFixed(1)}% to {highPenetrationRate.toFixed(1)}%</li>
          <li>Constant market size over 5 years</li>
        </ul>
        <p className="italic text-gray-400 mt-4">
          Disclaimer: This projection is based on hypothetical growth rates and assumes stable market conditions.
          Actual results may vary significantly due to market changes, competition, and other factors.
          This chart should be used for illustrative purposes only in strategic planning.
        </p>
      </div>
    </div>
  );
};

export default RevenueProjectionChart;
