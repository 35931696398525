import React from 'react';
import { Typography, Button, CircularProgress, Box } from '@mui/material';
import PendingIcon from '@mui/icons-material/Pending';

interface ResponseReviewProps {
  pendingResponses: (any | null)[];
  onSelect: (response: any) => void;
}

export default function ResponseReview({ pendingResponses, onSelect }: ResponseReviewProps) {
  return (
    <Box sx={{ width: '100%', overflowX: 'auto', display: 'flex', alignItems: 'flex-start' }}>
      {pendingResponses.map((response, index) => (
        <Box 
          key={index} 
          sx={{ 
            minWidth: '800px', 
            maxWidth: '500px', 
            margin: '0 8px', 
            padding: '16px', 
            border: '1px solid #ccc', 
            borderRadius: '4px'
          }}
        >
          <Typography variant="h6" gutterBottom>Response {index + 1}</Typography>
          <ResponseContent 
            response={response} 
            onSelect={onSelect}
          />
        </Box>
      ))}
    </Box>
  );
}

function ResponseContent({ response, onSelect }) {
  if (response === null) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100px' }}>
        <PendingIcon sx={{ marginRight: '8px' }} />
        <Typography>Pending...</Typography>
      </Box>
    );
  }

  if (response.error) {
    return <Typography color="error">{response.error}</Typography>;
  }

  const content = response.content || (response.data?.choices[0]?.message?.content ?? 'No content available');

  return (
    <Box sx={{ wordBreak: 'break-word' }}>
      <Typography 
        variant="body1" 
        sx={{ 
          backgroundColor: '#f0f0f0', 
          padding: '8px', 
          borderRadius: '4px', 
          marginBottom: '16px',
          fontWeight: 'bold'
        }}
      >
        {content}
      </Typography>
      <pre style={{ whiteSpace: 'pre-wrap', fontSize: '0.8rem', backgroundColor: '#f8f8f8', padding: '8px', borderRadius: '4px' }}>
        {JSON.stringify(response, null, 2)}
      </pre>
      <Button variant="contained" onClick={() => onSelect(response)} sx={{ marginTop: '16px' }}>
        Select
      </Button>
    </Box>
  );
}
