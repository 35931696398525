import React from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useAppDispatch, useAppSelector } from "src/store";
import { toggleChannelsList, toggleCreateChannelDialog } from 'src/features/theme/theme.slice';
import ChannelsList from './ChannelsList';

const ChannelsListDialog = () => {
  const dispatch = useAppDispatch();
  const open = useAppSelector(state => state.theme.isChannelsListOpen);

  const handleCreateChannelDialogClick = () => {
    dispatch(toggleCreateChannelDialog());
    onClose();
  };

  const onClose = () => {
    dispatch(toggleChannelsList());
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}>
        Your Oppies
        <Button onClick={() => handleCreateChannelDialogClick()} color="secondary">+ New Oppy</Button>
      </DialogTitle>
      <DialogContent dividers>
        <Box>
          <ChannelsList />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChannelsListDialog;
