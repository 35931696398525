import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Button, TextField, Select, MenuItem, Dialog, DialogTitle, DialogContent,
  Checkbox, FormControlLabel, Typography, Grid, Paper, IconButton, CircularProgress, AppBar, Toolbar, Tooltip, Popover,
  Box, Slider
} from '@mui/material';
import { Add, Remove, Send, Settings, Person, Computer, Functions, Visibility, VisibilityOff, Pending } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpClient } from 'src/libs';
import { API_ROUTES } from 'src/constants/routes';
import { useSnackbar } from 'notistack';
import ResponseReview from './ResponseReview';
import InfoIcon from '@mui/icons-material/Info';

const modelOptions = [
  { value: 'gpt-4', label: 'GPT-4' },
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
  { value: 'gpt-3.5-turbo', label: 'GPT-3.5 Turbo' },
  { value: 'gpt-3.5-turbo-16k', label: 'GPT-3.5 Turbo 16k' },
  { value: 'claude-3-opus-20240229', label: 'Claude 3 Opus' },
  { value: 'claude-3-sonnet-20240229', label: 'Claude 3 Sonnet' },
  { value: 'mixtral-8x7b-32768', label: 'Mixtral 8x7B' },
  { value: 'llama-2-70b-chat', label: 'LLaMA 2 70B' },
];

const roleIcons = {
  system: <Settings />,
  assistant: <Computer />,
  user: <Person />,
  function: <Functions />
};

const roleOrder = ['system', 'assistant', 'user', 'function'];

export default function OpenAIPromptTestTool() {
  const [showParams, setShowParams] = useState(false);
  const [inputMessages, setInputMessages] = useState('');
  const [functionsJson, setFunctionsJson] = useState('');
  const [apiKey, setApiKey] = useState('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');
  const [endpointUrl, setEndpointUrl] = useState('https://api.openai.com/v1/chat/completions');
  const [model, setModel] = useState('gpt-4o');
  const [maxTokens, setMaxTokens] = useState('300');
  const [functionCall, setFunctionCall] = useState('auto');
  const [temperature, setTemperature] = useState<number>(0.7);
  const [frequencyPenalty, setFrequencyPenalty] = useState<number>(0);
  const [presencePenalty, setPresencePenalty] = useState<number>(0);
  const [topP, setTopP] = useState<number>(1);
  const [responseFormat, setResponseFormat] = useState('{ "type": "text" }');
  const [streaming, setStreaming] = useState(false);
  const [messages, setMessages] = useState([]);
  const [apiResponse, setApiResponse] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverContent, setPopoverContent] = useState('');
  const [messageId, setMessageId] = useState('');
  const [analyticsResponse, setAnalyticsResponse] = useState(null);
  const [trackingId, setTrackingId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [numCalls, setNumCalls] = useState(1);
  const [openResponseReview, setOpenResponseReview] = useState(false);
  const [pendingResponses, setPendingResponses] = useState([]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const msgId = params.get('message_id');
    if (msgId) {
      setMessageId(msgId);
      handleAnalyticsCall(msgId);
    }
  }, [location]);

  const handleAnalyticsCall = async (id = messageId) => {
    if (!id) {
      enqueueSnackbar('Message ID is required', { variant: 'error' });
      return;
    }
    setLoading(true);
    try {
      const response = await httpClient.get(`${process.env.REACT_APP_API_V3_URL}${API_ROUTES.private_chat_analytics}`, {
        params: { message_id: id }
      });
      if (response.data.total_count === 0) {
        enqueueSnackbar('No matching records found', { variant: 'info' });
      } else {
        enqueueSnackbar('Analytics data retrieved successfully', { variant: 'success' });
        setAnalyticsResponse(response.data.trackings[0]);
      }
    } catch (error) {
      enqueueSnackbar(`Error: ${error.message}`, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const applyAnalyticsResponse = () => {
    if (analyticsResponse) {
      setModel(analyticsResponse.open_ai_model || '');
      setInputMessages(JSON.stringify(analyticsResponse.input_messages || [], null, 2));
      setFunctionsJson(JSON.stringify(analyticsResponse.open_ai_functions || [], null, 2));
      setTemperature(analyticsResponse.open_ai_temperature || 0.7);
      setMaxTokens(analyticsResponse.open_ai_max_tokens?.toString() || '');
      setFunctionCall(analyticsResponse.open_ai_function_call || 'auto');
      setFrequencyPenalty(analyticsResponse.frequency_penalty || 0);
      setPresencePenalty(analyticsResponse.presence_penalty || 0);
      setResponseFormat(JSON.stringify({ type: "text" })); // Default, as it's not in the response
      setStreaming(false); // Default, as it's not in the response
      setApiResponse(analyticsResponse.complete_response?.error?.message || '');
      setAnalyticsResponse(null);
    }
  };

  useEffect(() => {
    try {
      const parsedMessages = JSON.parse(inputMessages);
      setMessages(parsedMessages);
    } catch (error) {
      console.error('Invalid JSON for messages');
    }
  }, [inputMessages]);

  const handleApiCall = async () => {
    setLoading(true);
    setError(null);
    const responses = [];

    if (numCalls > 1) {
      setOpenResponseReview(true);
      setPendingResponses(new Array(numCalls).fill(null));

      for (let i = 0; i < numCalls; i++) {
        try {
          const response = await callApi();
          responses.push(response);
          setPendingResponses(prev => {
            const newResponses = [...prev];
            newResponses[i] = response;
            return newResponses;
          });
        } catch (error) {
          console.error(`Error in API call ${i + 1}:`, error);
          setPendingResponses(prev => {
            const newResponses = [...prev];
            newResponses[i] = { error: error.message || 'An error occurred during the API call' };
            return newResponses;
          });
        }
      }
    } else {
      try {
        const response = await callApi();
        handleSingleResponse(response);
      } catch (error) {
        console.error('Error in API call:', error);
        setError(error.message || 'An error occurred during the API call');
      }
    }

    setLoading(false);
  };

  const callApi = async () => {
    let streamedContent = '';

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    };

    let parsedFunctions;
    try {
      parsedFunctions = functionsJson ? JSON.parse(functionsJson) : [];
    } catch (error) {
      setApiResponse(`Error parsing functions JSON: ${error.message}`);
      setLoading(false);
      return;
    }

    let parsedResponseFormat;
    try {
      parsedResponseFormat = responseFormat ? JSON.parse(responseFormat) : undefined;
    } catch (error) {
      setApiResponse(`Error parsing response format JSON: ${error.message}`);
      setLoading(false);
      return;
    }

    const body = {
      model,
      messages: messages.filter(msg => !msg.hidden).map(msg => 
        msg.role === 'function' 
          ? { ...msg, name: msg.name || 'default_function_name' } 
          : msg
      ),
      functions: parsedFunctions,
      max_tokens: parseInt(maxTokens),
      function_call: functionCall,
      temperature: temperature,
      frequency_penalty: frequencyPenalty,
      presence_penalty: presencePenalty,
      top_p: topP,
      response_format: parsedResponseFormat,
      stream: streaming,
      tracking_id: trackingId || undefined,
    };

    try {
      const response = await fetch(endpointUrl, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(JSON.stringify(errorData, null, 2));
      }

      if (streaming) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        while (true) {
          const { done, value } = await reader.read();
          if (done) break;

          const chunk = decoder.decode(value);
          const lines = chunk.split('\n');

          for (const line of lines) {
            if (line.startsWith('data: ')) {
              try {
                const data = JSON.parse(line.slice(6));
                if (data.choices && data.choices[0].delta && data.choices[0].delta.content) {
                  streamedContent += data.choices[0].delta.content;
                  setApiResponse(streamedContent);
                }
              } catch (error) {
                console.error('Error parsing streaming data:', error);
              }
            }
          }
        }
        return { streaming: true, content: streamedContent };
      } else {
        const data = await response.json();
        const content = data.choices[0].message.function_call 
          ? JSON.stringify(data.choices[0].message.function_call, null, 2)
          : data.choices[0].message.content;
        return { streaming: false, data, content };
      }
    } catch (error) {
      setError(error.message);
      setApiResponse('');
      return { error: error.message };
    }
  };

  const handleSingleResponse = (response) => {
    if (response.error) {
      setError(response.error);
      return;
    }
    
    if (response.streaming) {
      setApiResponse(response.content);
    } else {
      setApiResponse(response.content);
      setMessages(prevMessages => [...prevMessages, { role: 'assistant', content: response.content }]);
    }
  };

  const handleResponseSelection = (selectedResponse) => {
    handleSingleResponse(selectedResponse);
    setOpenResponseReview(false);
    setPendingResponses([]);
  };

  const handleMessageUpdate = (index, content) => {
    const updatedMessages = [...messages];
    updatedMessages[index].content = content;
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleMessageDelete = (index) => {
    const updatedMessages = messages.filter((_, i) => i !== index);
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleMessageAdd = (index) => {
    const newMessage = { role: 'user', content: '' };
    const updatedMessages = [...messages.slice(0, index + 1), newMessage, ...messages.slice(index + 1)];
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleRoleChange = (index, newRole) => {
    const updatedMessages = [...messages];
    updatedMessages[index].role = newRole;
    if (newRole === 'function') {
      updatedMessages[index].name = updatedMessages[index].name || 'default_function_name';
    } else {
      delete updatedMessages[index].name;
    }
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleNameChange = (index, newName) => {
    const updatedMessages = [...messages];
    updatedMessages[index].name = newName;
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  const handleVisibilityToggle = (index) => {
    const updatedMessages = [...messages];
    updatedMessages[index].hidden = !updatedMessages[index].hidden;
    setMessages(updatedMessages);
    setInputMessages(JSON.stringify(updatedMessages, null, 2));
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          <Typography ml={10} variant="h6">OpenAI Prompt Test Tool</Typography>
          <Button color="inherit" onClick={() => setShowParams(true)} startIcon={<Settings />}>
            Edit Parameters
          </Button>
        </Toolbar>
      </AppBar>
      <Paper className="container mx-auto p-4" style={{ marginTop: '64px' }}>
        <Dialog open={showParams} onClose={() => setShowParams(false)}>
          <DialogTitle>Edit Parameters</DialogTitle>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select fullWidth value={model} onChange={(e) => setModel(e.target.value)}>
                  {modelOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Max Tokens" type="number" value={maxTokens} onChange={(e) => setMaxTokens(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Function Call" value={functionCall} onChange={(e) => setFunctionCall(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Temperature
                  <Tooltip title={
                    <React.Fragment>
                      <Typography color="inherit">Controls randomness in the output:</Typography>
                      <ul>
                        <li>0: Deterministic, always same output</li>
                        <li>0.3: Conservative, focused responses</li>
                        <li>0.7: Balanced creativity and coherence</li>
                        <li>1.0: More diverse, potentially less focused</li>
                      </ul>
                    </React.Fragment>
                  }>
                    <InfoIcon fontSize="small" style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </Typography>
                <Slider
                  value={temperature}
                  onChange={(_, newValue) => setTemperature(newValue as number)}
                  min={0}
                  max={2}
                  step={0.1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Frequency Penalty
                  <Tooltip title={
                    <React.Fragment>
                      <Typography color="inherit">Reduces repetition of token sequences:</Typography>
                      <ul>
                        <li>0: No penalty, may repeat phrases</li>
                        <li>0.5: Moderate diversity, less repetition</li>
                        <li>1.0: More diverse vocabulary and phrasing</li>
                        <li>2.0: Strongly avoids repetition, potentially less coherent</li>
                      </ul>
                    </React.Fragment>
                  }>
                    <InfoIcon fontSize="small" style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </Typography>
                <Slider
                  value={frequencyPenalty}
                  onChange={(_, newValue) => setFrequencyPenalty(newValue as number)}
                  min={0}
                  max={2}
                  step={0.1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Presence Penalty
                  <Tooltip title={
                    <React.Fragment>
                      <Typography color="inherit">Encourages the model to talk about new topics:</Typography>
                      <ul>
                        <li>0: No penalty, may focus on given topics</li>
                        <li>0.5: Gently encourages new topics</li>
                        <li>1.0: More likely to explore tangential subjects</li>
                        <li>2.0: Strongly prefers introducing new concepts, may go off-topic</li>
                      </ul>
                    </React.Fragment>
                  }>
                    <InfoIcon fontSize="small" style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </Typography>
                <Slider
                  value={presencePenalty}
                  onChange={(_, newValue) => setPresencePenalty(newValue as number)}
                  min={0}
                  max={2}
                  step={0.1}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom>
                  Top P
                  <Tooltip title={
                    <React.Fragment>
                      <Typography color="inherit">Controls diversity via nucleus sampling:</Typography>
                      <ul>
                        <li>0.1: Very focused, considers only most likely tokens</li>
                        <li>0.5: Balanced between focused and diverse</li>
                        <li>0.9: More diverse, considers less likely options</li>
                        <li>1.0: Considers all options, potentially less focused</li>
                      </ul>
                    </React.Fragment>
                  }>
                    <InfoIcon fontSize="small" style={{ marginLeft: '5px' }} />
                  </Tooltip>
                </Typography>
                <Slider
                  value={topP}
                  onChange={(_, newValue) => setTopP(newValue as number)}
                  min={0}
                  max={1}
                  step={0.05}
                  marks
                  valueLabelDisplay="auto"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField fullWidth label="Response Format (JSON)" value={responseFormat} onChange={(e) => setResponseFormat(e.target.value)} />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={streaming} onChange={(e) => setStreaming(e.target.checked)} />}
                  label="Streaming"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Input Messages JSON"
                  value={inputMessages}
                  onChange={(e) => setInputMessages(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Functions JSON"
                  value={functionsJson}
                  onChange={(e) => setFunctionsJson(e.target.value)}
                />
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
        <TextField
          fullWidth
          label="Message ID"
          value={messageId}
          onChange={(e) => {
            setMessageId(e.target.value);
            navigate(`?message_id=${e.target.value}`);
          }}
          margin="normal"
        />
        <Button 
          variant="contained" 
          onClick={() => handleAnalyticsCall()}
          disabled={!messageId || loading}
          style={{ marginBottom: '20px' }}
        >
          {loading ? 'Loading...' : 'Get Analytics'}
        </Button>
        {analyticsResponse && (
          <Button 
            variant="contained" 
            onClick={applyAnalyticsResponse}
            style={{ marginLeft: '10px', marginBottom: '20px' }}
            disabled={loading}
          >
            Apply Analytics
          </Button>
        )}
        <TextField
          fullWidth
          type="password"
          label="API Key"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Endpoint URL"
          value={endpointUrl}
          onChange={(e) => setEndpointUrl(e.target.value)}
          margin="normal"
        />
        <TextField
          fullWidth
          label="Tracking ID"
          value={trackingId}
          onChange={(e) => setTrackingId(e.target.value)}
          margin="normal"
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>Editable Messages</Typography>
            {messages.map((message, index) => (
              <Paper key={index} className="mb-2 relative" style={{ position: 'relative', maxWidth: '100%', margin: '0 auto 16px' }}>
                <Box sx={{ display: 'flex', height: '30px', width: '100%', mb: 1 }}>
                  <Select
                    value={message.role}
                    onChange={(e) => handleRoleChange(index, e.target.value)}
                    size="small"
                    sx={{ width: '45%', height: '100%' }}
                  >
                    {roleOrder.map((role) => (
                      <MenuItem key={role} value={role}>{role}</MenuItem>
                    ))}
                  </Select>
                  {message.role === 'function' && (
                    <TextField
                      value={message.name || ''}
                      onChange={(e) => handleNameChange(index, e.target.value)}
                      placeholder="Function name"
                      size="small"
                      sx={{ width: '45%', height: '100%' }}
                    />
                  )}
                  <IconButton
                    size="small"
                    onClick={() => handleVisibilityToggle(index)}
                    sx={{ width: '10%', height: '100%' }}
                  >
                    {message.hidden ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
                <TextField
                  fullWidth
                  multiline
                  value={message.content}
                  onChange={(e) => handleMessageUpdate(index, e.target.value)}
                  InputProps={{
                    style: { 
                      overflowY: 'auto', 
                      paddingLeft: '40px', 
                      paddingRight: '40px',
                      opacity: message.hidden ? 0.5 : 1
                    }
                  }}
                />
                <div style={{ position: 'absolute', top: 0, right: 0, display: 'flex', flexDirection: 'column' }}>
                  <IconButton size="small" onClick={() => handleMessageAdd(index)}>
                    <Add />
                  </IconButton>
                  <IconButton size="small" onClick={() => handleMessageDelete(index)}>
                    <Remove />
                  </IconButton>
                </div>
              </Paper>
            ))}
          </Grid>
        </Grid>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
          <Button 
            variant="contained" 
            onClick={handleApiCall} 
            startIcon={loading ? <CircularProgress size={24} /> : <Send />} 
            disabled={loading}
          >
            {loading ? 'Sending...' : 'Send API Call'}
          </Button>
          <Typography variant="body1" style={{ margin: '0 10px' }}>X</Typography>
          <TextField
            type="number"
            value={numCalls}
            onChange={(e) => setNumCalls(Math.min(Math.max(1, parseInt(e.target.value) || 1), 10))}
            inputProps={{ min: 1, max: 10 }}
            style={{ width: '60px' }}
          />
        </div>
        <Dialog open={openResponseReview} onClose={() => setOpenResponseReview(false)} fullWidth maxWidth="xl" PaperProps={{ style: { width: '100%', maxWidth: 'none', height: '100%', maxHeight: 'none' } }}>
          <ResponseReview 
            pendingResponses={pendingResponses} 
            onSelect={handleResponseSelection}
          />
        </Dialog>
        {error && (
          <Paper elevation={3} style={{ marginTop: '20px', padding: '10px', backgroundColor: '#ffebee' }}>
            <Typography variant="h6" color="error">Error:</Typography>
            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
              {error}
            </pre>
          </Paper>
        )}
        <Paper elevation={0} variant="outlined" className="mt-4 p-2">
          <Typography variant="body2">
            Remember to keep your API key secure and never share it publicly.
          </Typography>
        </Paper>
      </Paper>
    </>
  );
}
