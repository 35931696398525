import React, { useState } from 'react';
import { Box, Chip, Avatar, Card, CardContent, CardMedia, Typography, Grid } from "@mui/material";
import CalendarToday from '@mui/icons-material/CalendarToday';
import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import LocationOn from '@mui/icons-material/LocationOn';
import oppyPrompts from "src/prompts/oppyPrompts.json";

interface Prompt {
  title: string;
  description: string;
  avatar_url: string;
  chatbot_name: string;
  requirements: string[];
  system_message: string;
}

interface OppyPrompts {
  prompts: Prompt[];
}

const PromptCard = ({ prompt, isSelected, onClick }) => (
  <Card 
    onClick={onClick}
    sx={{
      width: 250,
      height: 350,
      display: 'flex',
      flexDirection: 'column',
      cursor: 'pointer',
      transition: 'transform 0.3s',
      transform: isSelected ? 'scale(1.05)' : 'scale(1)',
      '&:hover': { transform: 'scale(1.05)' },
      position: 'relative',
      overflow: 'hidden',
    }}
  >
    <CardMedia
      component="img"
      image={prompt.avatar_url}
      alt={prompt.chatbot_name}
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
      }}
    />
    <Box
      sx={{
        position: 'absolute',
        top: 8,
        right: 8,
        display: 'flex',
        gap: 0.5,
      }}
    >
      {prompt.requirements.includes('calendar') && <CalendarToday fontSize="small" sx={{ color: 'grey.300' }} />}
      {prompt.requirements.includes('admin_email') && <Email fontSize="small" sx={{ color: 'grey.300' }} />}
      {prompt.requirements.includes('admin_phone') && <Phone fontSize="small" sx={{ color: 'grey.300' }} />}
      {prompt.requirements.includes('address_required_for_scheduling') && <LocationOn fontSize="small" sx={{ color: 'grey.300' }} />}
    </Box>
    <CardContent sx={{ 
      flexGrow: 1, 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      position: 'relative',
      zIndex: 1,
    }}>
      <Typography variant="h6" component="div" sx={{ color: 'grey.100', mb: 1, maxWidth: '75%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {prompt.chatbot_name}
      </Typography>
      <Typography variant="subtitle1" sx={{ color: 'grey.300', mb: 1, maxWidth: '75%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {prompt.title}
      </Typography>
      <Typography variant="body2" sx={{ 
        mt: 'auto', 
        height: '4.5em',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        color: 'grey.400',
      }}>
        {prompt.description}
      </Typography>
    </CardContent>
  </Card>
);

const PromptPicker = ({ onPromptSelect, initialPrompt = "", channel, chatbotName, variant = "chips" }) => {
  const [selectedPrompt, setSelectedPrompt] = useState(null);

  const prompts: Prompt[] = [
    ...(Array.isArray(oppyPrompts) && oppyPrompts[0] ? (oppyPrompts[0] as OppyPrompts).prompts : []),
    ...(initialPrompt
      ? [
          {
            title: 'Reset',
            description: 'Reset to initial prompt',
            avatar_url: '',
            chatbot_name: '',
            requirements: [],
            system_message: initialPrompt,
          },
        ]
      : []),
  ];

  const handlePromptClick = (selectedPrompt: Prompt, index: number) => {
    setSelectedPrompt(index);
    onPromptSelect(selectedPrompt.system_message);
  };

  const renderChips = () => (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto',
        maxWidth: '100%',
        gap: '8px',
        padding: '8px',
        marginBottom: '5px',
        paddingLeft: '23px',
        scrollbarWidth: 'none', // for Firefox
        '&::-webkit-scrollbar': {
          display: 'none', // for Chrome and Safari
        },
      }}
    >
    {prompts.map((prompt, index) => (
      <Chip
        key={`prompt-${index}`}
        label={prompt.title}
        onClick={() => handlePromptClick(prompt, index)}
        color={prompt.title === 'Reset' ? 'default' : 'primary'}
        avatar={prompt.avatar_url ? <Avatar src={prompt.avatar_url} /> : undefined}
      />
    ))}
    </Box>
  );

  const renderCards = () => (
    <Box sx={{ 
      width: '100%', 
      p: { xs: 2, sm: 3, md: 4 },
      maxHeight: '70vh', // Set max height
      overflowY: 'auto', // Enable vertical scrolling
      '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for webkit
      scrollbarWidth: 'none', // Hide scrollbar for Firefox
      msOverflowStyle: 'none', // Hide scrollbar for IE/Edge
    }}>
      <Typography variant="h4" component="h2" align="center" gutterBottom>
        Choose a Prompt
      </Typography>
      <Grid container spacing={2} sx={{ justifyContent: "space-evenly" }}>
        {prompts.map((prompt, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index} sx={{ minWidth: 250 }}>
            <PromptCard 
              prompt={prompt} 
              isSelected={selectedPrompt === index}
              onClick={() => handlePromptClick(prompt, index)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );

  return variant === "chips" ? renderChips() : renderCards();
};

export default PromptPicker;
