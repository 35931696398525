// BEGIN /src/components/Channels/ConversationsList.tsx
import { useAppDispatch, useAppSelector } from "src/store";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, CardContent, CircularProgress, Hidden, IconButton, Skeleton, Typography, duration } from "@mui/material";
import { Button, Card } from "@mui/material";
import ConversationCreateDialogV2 from "./ConversationCreateDialogV2";
import emptyConversationsListSvg from "src/assets/svg/Oppy_careers_Inspector-Appraiser.svg";
import { dateTimeFormatter } from "src/libs/dateTimeFormatter";
import ConversationsListFilterBar from "./ConversationsListFilterBar";
import ConversationCard from "./ConversationCard";
import { fetchConversationsWithMessages, selectConversationsWithContacts } from "src/features/account/conversations.slice";
import { selectAllContacts } from "src/features/account/contacts.slice";

const styles = {
  mainContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexFlow: 'row',
    // pt: 2,
    flexGrow: 0,
    borderRadius: 3,
    height: '100vh',
    overflowY: 'scroll',
    minWidth: '320px',
    position: 'relative',
  },
  topBar: {
    position: 'absolute',
    top: '0px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // This centers the children vertically
    p: 2,
    background: '#FAFAFA',
    width: '100%',
  },
  topBarTitleAndFilterContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  topBarAction: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  conversationsContainer: {
    mt: 12,
    width: '100%',
    height: '100%',
    maxHeight: '100vh',
    overflowY: 'scroll',
    // backgroundColor: 'lightblue',
    display: 'flex',
    flexFlow: 'row',
    flexDirection: 'row', // Ensure groups are stacked vertically
    gap: '20px',
    p: 2,
    alignItems: 'stretch',
    flexWrap: 'wrap',
    transition: 'all 0.3s ease',
  },
  errorBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
  },
  conversationGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%', // Ensure full width
  },
  groupHeading: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  conversationCardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '20px',
    width: '100%',
  },
  emptyStateBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .MuiCard-root': {
      minWidth: 275,
    }
  },
  emptyStateContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: 3,
    '& img': {
      width: '50%',
      marginBottom: 2,
    }
  },
  dayTypography: {
    m: 2, 
    mt: 3, 
    ml: 0, 
    mb: 0, 
    alignItems: 'center'
  },
  dayCountTypography: {
    ml: 1,
    display: 'inline',
    fontWeight: 'normal',
    color: 'text.secondary'
  }
};

const ConversationsListV2 = ({ conversationsListStyle = 'open', handleConversationsListToggleClick=()=>{}}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [focusedConversation, setFocusedConversation] = useState<any | null>(null);
  const [createConversationOpen, setCreateConversationOpen] = useState(false);
  const { loading: conversationsLoading, error: conversationsLoadError, initialLoad: conversationsInitialLoad } = useAppSelector(state => state.conversations);
  const contacts = useAppSelector(selectAllContacts);
  const contactsInitialLoad = useAppSelector(state => state.contacts.initialLoad);
  const contactsError = useAppSelector(state => state.contacts.error);
  const conversations = useAppSelector(selectConversationsWithContacts);

  useEffect(() => {
    if (focusedConversation) {
      navigate(`?conversation_id=${focusedConversation.id}&channel_id=${focusedConversation.channel_id}`)
    }
  }, [focusedConversation]);

  // Add contacts as a dependency to useEffect to trigger re-render when contacts change
  useEffect(() => {
    if (!focusedConversation && conversations.length > 0) {
      setFocusedConversation(conversations[0]);
    }
    if (conversations.length === 0 && !conversationsLoading && conversationsInitialLoad) {
      setCreateConversationOpen(true);
    }
  }, [conversations, conversationsLoading, contacts, conversationsInitialLoad]);


  const displayOrder = ["Priority", "Today", "Yesterday", "Last 7 Days", "Older", "Closed"];

  const conversationsGroupedByDay = (conversations) => {
    const groupedConversations = displayOrder.reduce((accumulator, label) => {
      accumulator[label] = { conversations: [], count: 0 };
      return accumulator;
    }, {});

    const priorityStatuses = ['needs_attention', 'error', 'processing_autocomplete']; // Define priority statuses
    const conversationsCopy = [...conversations];

    // Sort the copy of the conversations array
    conversationsCopy.sort((a, b) => {
      let statusPriorityA = priorityStatuses.includes(a.status) ? 0 : 1;
      let statusPriorityB = priorityStatuses.includes(b.status) ? 0 : 1;
      if (statusPriorityA !== statusPriorityB) {
        return statusPriorityA - statusPriorityB;
      }
      let timeA = new Date(a.latest_message_at || a.updated_at).getTime();
      let timeB = new Date(b.latest_message_at || b.updated_at).getTime();
      return timeB - timeA; // Descending order
    });

    // Group priority conversations separately
    conversationsCopy.forEach(conversation => {
      if (priorityStatuses.includes(conversation.status)) {
        groupedConversations['Priority'].conversations.push(conversation);
        groupedConversations['Priority'].count++;
      } else if (conversation.status === 'closed') {
        groupedConversations['Closed'].conversations.push(conversation);
        groupedConversations['Closed'].count++;
      } else {
        const label = dateTimeFormatter(conversation.latest_message_at || conversation.updated_at, 'humanized_time_period');
        groupedConversations[label].conversations.push(conversation);
        groupedConversations[label].count++;
      }
    });

    return groupedConversations;
  };

  const groupedConversations = conversationsGroupedByDay(conversations || []);

  const loadingSkeletons = Array.from(new Array(5)).map((_, index) => (
    <Box key={index} sx={{
      borderRadius: '10px',
      mb: 2,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      height: 250,
      animationDuration: '4s',
    }}>
      <Skeleton variant="rectangular" width={'350px'} height={'250px'} animation={'wave'} sx={{ maxWidth: '100%', minWidth: '300px', borderRadius: '10px' }}>
        <Skeleton variant="rectangular" width={'100%'} height={'25px'} animation={'wave'} />
        <Skeleton variant="rectangular" width={'100%'} height={'150px'} animation={'wave'} />
      </Skeleton>
    </Box>
  ));

  return (
    <>

      <Box sx={styles.mainContainer}>
        <Box sx={styles.topBar}>
          <Box sx={styles.topBarTitleAndFilterContent}>
            <Typography variant="h5">Conversations</Typography>          
            <ConversationsListFilterBar />
          </Box>
          <Box sx={styles.topBarAction}>
            <Button variant="outlined" color="secondary" onClick={() => setCreateConversationOpen(true)}>+ New conversation</Button>
          </Box>
        </Box>
        <Box sx={styles.conversationsContainer}>
          {conversationsLoadError && 
          <Box sx={styles.errorBox}>
            <Typography>Having trouble connecting to your conversations. {conversationsLoadError}</Typography>
            <Button onClick={() => dispatch(fetchConversationsWithMessages({}))}>Retry</Button>
          </Box>}
          {conversationsLoading ?
            <Box sx={styles.conversationGroup}>
              <Box sx={styles.groupHeading}>
                  <Typography variant="h5" sx={styles.dayTypography}>
                    <Skeleton variant="text" width={'300px'} height={'50px'} />
                  </Typography>
              </Box>
              <Box sx={styles.conversationCardsContainer}>
                {loadingSkeletons}
              </Box>
            </Box>
          :
            displayOrder.map((group) => (
              groupedConversations[group].count ?
                <Box sx={styles.conversationGroup} key={group}>
                  <Box sx={styles.groupHeading}>
                    <Typography variant="h5" sx={styles.dayTypography}>
                      {group}
                      <Typography variant="h5" sx={styles.dayCountTypography}>
                        {groupedConversations[group].count}
                      </Typography>
                    </Typography>
                  </Box>
                  <Box sx={styles.conversationCardsContainer}>
                    {groupedConversations[group].conversations.map((conversation) => (
                      <ConversationCard handleConversationsListToggleClick={handleConversationsListToggleClick} key={conversation.id} conversation={conversation} />
                    ))}
                  </Box>
                </Box>
                : null
            ))
          }
          {conversations.length === 0 && !conversationsLoading && 
            <Box sx={styles.emptyStateBox}>
              <Card>
                <CardContent>
                  <Box sx={styles.emptyStateContent}>
                    <img src={emptyConversationsListSvg} alt="Empty" />
                    <Typography variant="subtitle1" gutterBottom>
                      Seems you don't have any conversations yet.
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Pro tip: Start a conversation or begin sharing your Oppies!
                    </Typography>
                    <Button sx={{mt:5}} variant="outlined" color="secondary" onClick={() => setCreateConversationOpen(true)}>+ New conversation</Button>

                  </Box>
                </CardContent>
              </Card>
            </Box>
            
          }

        </Box>
      </Box >
      <ConversationCreateDialogV2 
        open={createConversationOpen} 
        handleClose={() => setCreateConversationOpen(false)} 
      />
      {/* end conversations list */}
    </>

  );
};

export default ConversationsListV2;
