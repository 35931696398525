import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import { TextField, Button, Typography, Box, Stack, Tooltip } from '@mui/material';
import { Download, Link, Person, Sms, Tune } from '@mui/icons-material';
import { styled } from '@mui/system';
import { selectChannelById } from 'src/features/account/channels.slice';
import { useAppSelector } from 'src/store';

const StyledWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: 400,
  margin: 'auto',
}));

const QRCodeWrapper = styled('div')({
  position: 'relative',
  marginTop: 8,
  marginBottom: 8,
});

const AvatarOverlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  pointerEvents: 'none',
});

const DownloadButton = styled(Button)(({ theme }) => ({
  position: 'absolute',
  top: 8,
  right: 8,
  minWidth: 'unset',
  padding: theme.spacing(1),
}));

const QRCodeGenerator = ({ defaultPhoneNumber, defaultEmail, defaultBaseUrl, defaultUrl, defaultInitialMessage }) => {
  const [url, setUrl] = useState(defaultUrl);
  const [urlInput, setUrlInput] = useState(defaultUrl);
  const [showDownload, setShowDownload] = useState(false);
  const [method, setMethod] = useState<string>(defaultPhoneNumber ? 'sms' : defaultEmail ? 'email' : 'website');
  const [initialMessage, setInitialMessage] = useState<string>(defaultInitialMessage);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [helperTextIndex, setHelperTextIndex] = useState(0);

  const helperTexts = [
    "Spark a great conversation with a friendly opener",
    "Hi, I saw your listing at 123 Main St. Is it still available?",
    "I got your card at the open house. Can we discuss the property?",
    "Your Facebook ad caught my eye. I'm interested in selling my home.",
    "Saw your 'For Sale' sign. What's the asking price for 456 Oak Ave?",
    "I found your flyer about investment properties. Can you tell me more?",
    "Your Instagram post about new listings intrigued me. What's available?",
    "I'm relocating to the area. Can you help me find a home?",
    "Noticed your billboard on Highway 1. Do you have any beachfront properties?",
    "Your website mentioned pre-approval. How can I start that process?",
    "I'm interested in your home renovation services. My project involves...",
    "Saw your ad for landscaping. Can you give me a quote for...",
    "Your interior design portfolio is amazing. I need help with my...",
    "I'm looking for a property manager for my rental. Are you available to...",
    "Your solar panel installation offer caught my attention. My address is...",
    "I need a home inspection before buying. When can you...",
    "Interested in your smart home installation services. My budget is...",
    "Saw your moving company truck. I'm planning to move on...",
    "Your pest control services are needed at my property. The issue is...",
    "I want to refinance my mortgage. My current rate is..."
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setHelperTextIndex((prevIndex) => (prevIndex + 1) % helperTexts.length);
    }, 4000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (!showAdvanced && method) {
      let newUrl = '';
      if (method === "email") {
        newUrl = `mailto:${defaultEmail}?subject=Hey ${defaultEmail}!&body=${initialMessage}`;
      } else if (method === "sms") {
        newUrl = `SMSTO:${defaultPhoneNumber}:${initialMessage}`;
      } else if (method === "website") {
        newUrl = defaultBaseUrl + `?initial_message=${initialMessage}`;
      }
      setUrlInput(newUrl);
    }
  }, [method, initialMessage, showAdvanced]);

  const handleMethodClick = (newMethod: string) => {
    setMethod(newMethod);
    setShowAdvanced(false);
  }

  const handleUrlInputChange = (e) => {
    if (!showAdvanced) {
      return;
    }
    setUrlInput(e.target.value);
  }

  const handleInitialMessageChange = (e) => {
    if (showAdvanced) {
      return;
    }
    setInitialMessage(e.target.value);
    updateUrlInput(e.target.value);
  }

  const updateUrlInput = (currentInitialMessage) => {
    if (showAdvanced) {
      return;
    }
    if (method === "email") {
      setUrlInput(`mailto:${defaultEmail}?subject=Hey ${defaultEmail}!&body=${currentInitialMessage}`);
    } else if (method === "sms") {
      setUrlInput(`SMSTO:${defaultPhoneNumber}:${currentInitialMessage}`);
    } else if (method === "website") {
      setUrlInput(defaultBaseUrl + `?initial_message=${currentInitialMessage}`);
    }
  }

  const handleDownload = () => {
    const canvas = document.getElementById('qr-code') as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'oppy-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Stack spacing={2}>
      <Tooltip title="Choose how people will contact you. They can send you a text message, an email, or visit your chat landing page when they scan the QR code." arrow>
        <Box>
          <Typography variant="subtitle1" gutterBottom>Step 1: Choose contact method</Typography>
          <Stack direction="row" spacing={2} justifyContent="center">
                <Button 
                  startIcon={<Sms />} 
                  onClick={() => defaultPhoneNumber ? handleMethodClick("sms") : null} 
                  variant='contained' 
                  color={method === 'sms' ? 'primary' : 'secondary'}
                  disabled={!defaultPhoneNumber}
                  component={!defaultPhoneNumber ? "a" : "button"}
                  href={!defaultPhoneNumber ? "/channels?channel_id=${channel.id}" : undefined}
                  sx={{ height: '36px' }}
                >
                  Text Message
                </Button>
            {defaultEmail && <Button startIcon={<Person />} onClick={() => handleMethodClick("email")} variant='contained' color={method === 'email' ? 'primary' : 'secondary'} sx={{ height: '36px' }}>Email</Button>}
            {defaultBaseUrl && <Button startIcon={<Link />} onClick={() => handleMethodClick("website")} variant='contained' color={method === 'website' ? 'primary' : 'secondary'} sx={{ height: '36px' }}>Website</Button>}
          </Stack>
        </Box>
      </Tooltip>

      <Tooltip title="Write a friendly message that people will see when they scan your QR code. This helps them start a conversation with you easily." arrow>
        <Box>
          <Typography variant="subtitle1" gutterBottom>Step 2: Craft your opener</Typography>
          <TextField
            fullWidth
            variant="outlined"
            value={initialMessage}
            onChange={handleInitialMessageChange}
            placeholder="Hi! I'm interested in..."
            helperText={helperTexts[helperTextIndex]}
            hidden={showAdvanced}
            sx={{
              '& .MuiFormHelperText-root': {
                position: 'absolute',
                bottom: '-24px',
                left: 0,
                right: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                animation: 'slideInOut 6s linear infinite',
                maxWidth: '100%',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  display: 'none'
                },
                msOverflowStyle: 'none',
                scrollbarWidth: 'none'
              },
              '@keyframes slideInOut': {
                '0%, 100%': { transform: 'translateX(100%)', opacity: 0 },
                '5%, 95%': { transform: 'translateX(0)', opacity: 1 },
              },
              marginBottom: '24px',
            }}
          />
        </Box>
      </Tooltip>

      <Tooltip title="This is your QR code. You can print it and put it on flyers, business cards, yard signs, or anywhere people might see it. When they scan it with their phone camera, they'll be able to contact you instantly!" arrow>
        <Box>
          <Typography variant="subtitle1" gutterBottom>Step 3: Your QR Code</Typography>
          <QRCodeWrapper>
            <QRCode id="qr-code" value={urlInput} size={256} level="H" includeMargin={false} />
            <HandHoldingPhone />
          </QRCodeWrapper>
        </Box>
      </Tooltip>

      <Tooltip title="Save your QR code as an image file. You can then print it or share it online." arrow>
        <Button startIcon={<Download />} variant="contained" color="primary" onClick={handleDownload} sx={{ height: '36px' }}>
          Download QR Code
        </Button>
      </Tooltip>

      <Tooltip title="For tech-savvy users: Customize the QR code's link if you want more control over where it leads." arrow>
        <Button startIcon={<Tune />} variant="text" color="secondary" onClick={() => setShowAdvanced(!showAdvanced)} sx={{ height: '36px' }}>
          Advanced Options
        </Button>
      </Tooltip>

      {showAdvanced && (
        <TextField
          fullWidth
          variant="outlined"
          value={urlInput}
          onChange={handleUrlInputChange}
          placeholder="Custom URL"
        />
      )}
    </Stack>
  );
};

const HandHoldingPhone = () => (
  <Box
    component="svg"
    viewBox="0 0 100 100"
    sx={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      animation: 'fadeInOut 2s ease-in-out',
      '@keyframes fadeInOut': {
        '0%, 100%': { opacity: 0 },
        '50%': { opacity: 0.7 },
      },
    }}
  >
    {/* Simple SVG hand and phone shape */}
    <path d="M30,70 Q40,60 50,70 Q60,80 70,70 L80,90 Q70,100 50,100 Q30,100 20,90 Z" fill="#f0f0f0" />
    <rect x="40" y="20" width="20" height="40" rx="2" fill="#333" />
  </Box>
);

export default QRCodeGenerator;
