import React, { useState, useRef, useEffect } from 'react';
import {
  Box, Typography, Button, TextField, Paper, IconButton,
  useMediaQuery, useTheme, Divider, FormControl, InputLabel, Select, MenuItem
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ChevronLeft, ChevronRight, Phone, Send, Chat, Email, Language, QrCode2,
  ExpandMore, Settings, AdminPanelSettings
} from '@mui/icons-material';
import { MilkdownWYSIWYGEditor } from '../Milkdown/MilkdownWYSIWYGEditor';
import { useAppSelector, useAppDispatch } from 'src/store';
import ChannelHeader from './ChannelHeader';
import ImportWebsiteInput from './ImportWebsiteInput';
import PagesDialog from './PagesDialog';
import { selectChannelById } from 'src/features/account/channels.slice';
import PromptPicker from './PromptPicker';

const Section = styled(Paper)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  boxShadow: 'none',
  border: `1px solid ${theme.palette.divider}`,
}));

const setDefaultGoals = (channel, user) => {
  if (channel.config?.goals) {
    if (channel.config.goals.length > 0) {
      return channel.config.goals
    } else {
      return [
        "",
      ]
    }
  } else if (user.google_calendar_connected && channel.config?.booking_enabled) {
    return [
      "Get user's name, email, and preferred time for appointment.",
      "Check calendar for availability within user's suggested time range.",
      "Book the appointment at an available time slot or offer alternatives.",
    ]
  } else {
    return [
      "Capture Email, Name & Phone Number",
      "Answer user's questions about your business and ask them questions to qualify them",
      "Share social channels for them to stay connected"
    ]
  }
}

const Channel = ({ channelId }) => {
  const user = useAppSelector(state => state.user);
  const channel = useAppSelector(state => selectChannelById(state, channelId));
  const [previewSystemMessage, setPreviewSystemMessage] = useState(channel?.config?.system_message || '');
  const [unpublishedChanges, setUnpublishedChanges] = useState(false);

  const [goals, setGoals] = useState(() => setDefaultGoals(channel, user));
  const [prompt, setPrompt] = useState(channel.config?.knowledge_base || '');
  const [pages, setPages] = useState((channel.config?.pages && channel.config.pages.length > 0 && channel.config.pages) || [{ tracking_id: '', page_url: '' }]);
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    setGoals(setDefaultGoals(channel, user));
    setPrompt(channel.config?.knowledge_base || '');
    setPages((channel.config?.pages && channel.config.pages.length > 0 && channel.config.pages) || [{ tracking_id: '', page_url: '' }]);
    setChanged(false);
  }, [channel, user]);

  const handleGoalChange = (index, value) => {
    const newGoals = [...goals];
    newGoals[index] = value;
    setGoals(newGoals);
    if (channel.config?.goals !== newGoals) {
      setChanged(true);
    }
  };

  const handlePagesUpdate = (updatedPages) => {
    setPages(updatedPages);
  };

  const handlePromptChange = (text) => {
    setPrompt(text);
    if (channel.config?.knowledge_base !== text) {
      setChanged(true);
    }
  };

  const handlePromptSelect = (selectedPrompt) => {
    setPreviewSystemMessage(selectedPrompt);
    setUnpublishedChanges(true);
  };


  const MainContent = () => (
    <Box>
      <ChannelHeader channel={channel} />
    </Box>
  );

  return (
    <Box sx={{ 
      width: '100%', 
      height: '100%', 
      overflow: 'auto', 
      padding: 0, 
      display: 'flex', 
      justifyContent: 'center' 
    }}>
      <Box sx={{ 
        width: '100%'
      }}>
        <MainContent />
      </Box>
    </Box>
  );
};

export default Channel;
