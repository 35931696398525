import { useState } from 'react';
import Button from '@mui/material/Button';
import { Box, CircularProgress } from '@mui/material';
import { useAppDispatch } from 'src/store';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import ErrorIcon from '@mui/icons-material/Error';
import { conversationAutocompleteNow, updateConversationDeliveryState } from 'src/features/account/conversations.slice';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const GenerateReplyButton = ({ conversation }) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    if (isLoading) return;
    
    if (conversation.status === 'processing_autocomplete' && !confirm('A response is already being generated. Generate another?')) {
      return;
    }
    
    setIsLoading(true);
    try {
      // if (conversation.delivery_state !== 'pause') {
      //   await dispatch(updateConversationDeliveryState({
      //     conversationId: conversation.id,
      //     channelId: conversation.channel_id,
      //     deliveryState: 'pause'
      //   }));
      // }
      await dispatch(conversationAutocompleteNow({ conversationId: conversation.id, channelId: conversation.channel_id }));
    } finally {
      setIsLoading(false);
    }
  }

  const getButtonContent = () => {
    if (isLoading) return <CircularProgress size={24} color="inherit" />;
    if (conversation.autocomplete_status === 'processing_autocomplete') return <Box className="dot-typing" />;
    if (conversation.autocomplete_status === 'queued') return <PendingActionsIcon />;
    if (conversation.autocomplete_status === 'error') return <ErrorIcon />;
    return <AutoAwesomeIcon />;
  }

  return (
    <Button 
      variant="contained"
      disabled={isLoading}
      onClick={handleClick}
      sx={{ borderRadius: '0', maxWidth: '75px' }}
    >
      {getButtonContent()}
    </Button>
  );
};

export default GenerateReplyButton;
