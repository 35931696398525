//BEGIN frontend/src/features/account/channels.slice.tsx
import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { API_ROUTES } from 'src/constants/routes';
import { httpClient } from 'src/libs'
import { fetchConversationsWithMessages } from './conversations.slice';
import ChannelsService from 'src/services/channels.services';
import { serializeErrorMessage } from 'src/utils';
import { enqueueSnackbar } from 'notistack';
import { RootState } from 'src/store';

interface ChannelsState {
  channels: any[];
  error: string | null;
  channelsLoading: boolean;
  initialLoad: false,
  leads: number;
  bookings: number;
  allChannelReplies: number;
  audioNotificationsEnabled: boolean;
}

export interface Channel {
  id: string;
  title: string;
  created_at: string;
  updated_at: string;
  config: any;
  flow: string;
  functions: any;
  twilio_phone_number: string | null;
  delivery_state: string;
  delivery_delay: number;
  bookings_created: number;
  account_id: string;
  email_address: string;
  channel_email_address: string;
  embed_key: string;
  picture_url: string;
  description: string;
  admin_settings: any;
  avatar_url: string;
  chatbot_name: string;
  autocomplete_schedule: string;
  public_functions: string[];
  admin_functions: string[];
  faqs: { 
    id: string, 
    question: string, 
    answer: string,
    metadata: any,
    created_at: string,
    updated_at: string,
    channel_id: string
  }[];
}

// const initialState: ChannelsState = {
//   channels: [],
//   error: null,
//   channelsLoading: false,
//   initialLoad: false,
//   leads: 0,
//   bookings: 0,
//   allChannelReplies: 0,
//   audioNotificationsEnabled: false,
// }
export const channelsAdapter = createEntityAdapter<Channel>({
  selectId: (channel) => channel.id,
  sortComparer: (a, b) => {
    // Convert date strings to Date objects for comparison
    const dateA = new Date(a.updated_at);
    const dateB = new Date(b.updated_at);
    return dateB.getTime() - dateA.getTime(); // Sort by least recently updated first
  },
});

const initialState = channelsAdapter.getInitialState({
  channels: [],
  error: null,
  channelsLoading: false,
  initialLoad: false,
  leads: 0,
  bookings: 0,
  allChannelReplies: 0,
  audioNotificationsEnabled: false,
});


export const fetchChannels = createAsyncThunk(
  'channels/fetchChannels',
  async (_, thunkAPI) => {
    try {
      const response = await ChannelsService.getChannels();
      console.log("response", response);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const fetchChannelConversations = createAsyncThunk(
  'conversations/fetchChannelConversations',
  async (channelId: any, thunkAPI) => {
    try {
      const response = await ChannelsService.getChannelConversations(channelId);
      return response.data.conversations;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateChannel = createAsyncThunk(
  'channels/updateChannel',
  async (data: { channelData: any }, thunkAPI) => {
    try {
      const response = await ChannelsService.updateChannel(data.channelData);
      return response.data.channel;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const deleteChannel = createAsyncThunk(
  'channels/deleteChannel',
  async (channelId: string, thunkAPI) => {
    try {
      const response = await ChannelsService.destroy(channelId);
      return response.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const releasePhoneNumber = createAsyncThunk(
  'channels/releasePhoneNumber',
  async (channelId: string, thunkAPI) => {
    try {
      const response = await ChannelsService.releasePhoneNumber(channelId);
      return response;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const getChannelUsage = createAsyncThunk(
  'channel/usage/getUsage',
  async (id: string, thunkAPI) => {
    try {
      const res = await ChannelsService.getUsage(id);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateChannelPrompt = createAsyncThunk(
  'channels/updateChannelPrompt',
  async (payload: { channelId: string, prompt: string }, thunkAPI) => {
    const { channelId, prompt } = payload;
    try {
      const res = await ChannelsService.updateChannelPrompt(channelId, prompt);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateBccAndFallbackPhone = createAsyncThunk(
  'channels/updateBccAndFallbackPhone',
  async (payload: { channelId: string, smsNotificationsEnabled: boolean, fallbackPhone: string }, thunkAPI) => {
    const { channelId, smsNotificationsEnabled, fallbackPhone } = payload;
    try {
      const res = await ChannelsService.updateBccAndFallbackPhone(channelId, smsNotificationsEnabled, fallbackPhone);
      return res.data;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateChannelDelivery = createAsyncThunk(
  'channel/updateDelivery',
  async ({ data, channelId }: { data: any; channelId: string }, thunkAPI) => {
    try {
      const response = await ChannelsService.updateDelivery(channelId, data);
      return response.data.channel;
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const updateChannelAdminSettings = createAsyncThunk(
  'channels/updateChannelAdminSettings',
  async (payload: { channelId: string, data: { admin_settings: { admin_email?: { email: string }, admin_phone?: { phone: string } } } }, thunkAPI) => {
    const { channelId, data } = payload;
    try {
      const res = await ChannelsService.updateChannelAdminSettings(channelId, data);
      if (res.success) {
        return res.channel;
      } else {
        throw new Error(res.error);
      }
    } catch (error) {
      const message = serializeErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
)

export const processPusherChannelUpdated = createAsyncThunk(
  'channels/processPusherChannelUpdated',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

export const processPusherChannelCreated = createAsyncThunk(
  'channels/processPusherChannelCreated',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

export const processPusherChannelDeleted = createAsyncThunk(
  'channels/processPusherChannelDeleted',
  async (data: { object: any }, thunkAPI) => {
    return data.object;
  }
)

// New async thunks
export const updatePublicFunctions = createAsyncThunk(
  'channels/updatePublicFunctions',
  async ({ channelId, publicFunctions }: { channelId: string, publicFunctions: string[] }, { rejectWithValue }) => {
    try {
      const response = await ChannelsService.updatePublicFunctions(channelId, publicFunctions);
      if (!response.data.success) {
        return rejectWithValue(response.data.error);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(serializeErrorMessage(error));
    }
  }
);

export const updateAdminFunctions = createAsyncThunk(
  'channels/updateAdminFunctions',
  async ({ channelId, adminFunctions }: { channelId: string, adminFunctions: string[] }, { rejectWithValue }) => {
    try {
      const response = await ChannelsService.updateAdminFunctions(channelId, adminFunctions);
      return response.data;
    } catch (error) {
      return rejectWithValue(serializeErrorMessage(error));
    }
  }
);

export const updateAutocompleteSchedule = createAsyncThunk(
  'channels/updateAutocompleteSchedule',
  async (payload: { channelId: string, schedule: string }, { rejectWithValue }) => {
    try {
      const res = await ChannelsService.updateAutocompleteSchedule(payload.channelId, payload.schedule);
      return res.data;
    } catch (error) {
      return rejectWithValue(serializeErrorMessage(error));
    }
  }
);

export const updateChannelSystemMessage = createAsyncThunk(
  'channels/updateSystemMessage',
  async ({ channelId, systemMessage }: { channelId: string; systemMessage: string }, { rejectWithValue }) => {
    try {
      const response = await httpClient.patch(`/channels/${channelId}`, { config: { system_message: systemMessage } });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const channelsSlice = createSlice({
  name: 'channels',
  initialState: initialState,
  reducers: {
    upsertChannel: channelsAdapter.upsertOne,
    upsertChannels: channelsAdapter.upsertMany,
    removeChannel: channelsAdapter.removeOne
  },
  extraReducers: builder => {
    builder
      .addCase(processPusherChannelUpdated.fulfilled, (state, action) => {
        console.log("action.payload upserting a channel!", action.payload);
        channelsAdapter.upsertOne(state, action.payload);
      })
      .addCase(processPusherChannelCreated.fulfilled, (state, action) => {
        state.channels = [...state.channels, action.payload];
      })
      .addCase(processPusherChannelDeleted.fulfilled, (state, action) => {
        state.channels = state.channels.filter(channel => channel.id !== action.payload.id);
      })
      .addCase(fetchChannels.pending, state => {
        state.channelsLoading = true;
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.channelsLoading = false;
        state.initialLoad = true;
        // Add default conversations to your channels
        channelsAdapter.upsertMany(state, action.payload);
        state.channels = action.payload.map((channel: any) => ({
          ...channel,
          conversations: channel?.conversations ?? [],
        }));
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.error.message;
      })
      .addCase(updateChannel.fulfilled, (state, action) => {
        state.channels = state.channels.map(channel =>
          channel.id === action.payload.id ? action.payload : channel
        );
      })
      .addCase(releasePhoneNumber.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(releasePhoneNumber.fulfilled, (state, action) => {
        state.channelsLoading = false;
        fetchChannels();
        // state.channels = state.channels.map(channel =>
        //   channel.id === action.payload.id ? action.payload : channel
        // );
      })
      .addCase(fetchChannelConversations.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(fetchChannelConversations.fulfilled, (state, action) => {
        if (action.payload.length > 0) {
          action.payload.forEach((conversation: any) => {
            // Extract channel_id for each conversation
            const channelId = conversation.channel_id;

            // find channel and update conversations array
            state.channels = state.channels.map((channel) =>
              channel.id === channelId
                ? { ...channel, conversations: [...(channel.conversations || []), conversation] }
                : channel
            );
          });
        }
        state.channelsLoading = false;
      })
      .addCase(fetchChannelConversations.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchConversationsWithMessages.fulfilled, (state, action) => {
        console.log("channels are receiving conversations with messages");
        state.channels = state.channels.map(channel =>
          channel.id === action.payload.channel_id
            ? { ...channel, conversations: Array.isArray(channel.conversations) ? [...channel.conversations, action.payload] : [action.payload] }
            : channel
        );
      })
      .addCase(getChannelUsage.fulfilled, (state, action) => {
        state.leads = action.payload.leads_created;
        state.bookings = action.payload.bookings_created;
        state.allChannelReplies = action.payload.all_channel_replies;
        state.channelsLoading = false;
        state.error = null;
      })
      .addCase(getChannelUsage.pending, (state, _) => {
        state.channelsLoading = true;
      })
      .addCase(getChannelUsage.rejected, (state, action) => {
        state.leads = 0;
        state.bookings = 0;
        state.allChannelReplies = 0;
        state.channelsLoading = false;
        state.error = action.payload as string;
      })
      .addCase(updateChannelPrompt.fulfilled, (state, action) => {
        state.channels = state.channels.map(channel =>
          // can optimize later to only replace system message in config
          channel.id === action.payload.id ? action.payload : channel
        );
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar('Prompt updated successfully!', { variant: 'success' });
      })
      .addCase(updateChannelPrompt.pending, (state, _) => {
        state.channelsLoading = true;
      })
      .addCase(updateChannelPrompt.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updateBccAndFallbackPhone.fulfilled, (state, action) => {
        state.channels = state.channels.map(channel =>
          channel.id === action.payload.id ? action.payload : channel
        );
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar('Notification settings updated successfully!', { variant: 'success' });
      })
      .addCase(updateBccAndFallbackPhone.pending, (state, _) => {
        state.channelsLoading = true;
      })
      .addCase(updateBccAndFallbackPhone.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updateChannelDelivery.fulfilled, (state, action) => {
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar("Updated Delivery", { variant: 'success' });
      })
      .addCase(updateChannelDelivery.pending, (state, _) => {
        state.channelsLoading = true;
      })
      .addCase(updateChannelDelivery.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error', autoHideDuration: 10000 });
      })
      .addCase(deleteChannel.fulfilled, (state, action) => {
        state.channels = state.channels.filter(channel => channel.id !== action.payload.channel_id);
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar(action.payload.message, { variant: 'success' });
      })
      .addCase(deleteChannel.pending, (state, _) => {
        state.channelsLoading = true;
      })
      .addCase(deleteChannel.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error', autoHideDuration: 10000 });
      })
      .addCase(updateChannelAdminSettings.pending, (state, action) => {
        const channelId = action.meta.arg.channelId;
        state.channels = state.channels.map(channel =>
          channel.id === channelId ? { ...channel, admin_settings: { ...channel.admin_settings, loading: true } } : channel
        );
      })
      .addCase(updateChannelAdminSettings.fulfilled, (state, action) => {
        state.channels = state.channels.map(channel =>
          channel.id === action.payload.channel.id ? { ...action.payload.channel, admin_settings: { ...action.payload.channel.admin_settings, loading: false } } : channel
        );
        state.error = null;
        enqueueSnackbar('Admin settings updated successfully!', { variant: 'success' });
      })
      .addCase(updateChannelAdminSettings.rejected, (state, action) => {
        const channelId = action.meta.arg.channelId;
        state.channels = state.channels.map(channel =>
          channel.id === channelId ? { ...channel, admin_settings: { ...channel.admin_settings, loading: false } } : channel
        );
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updatePublicFunctions.fulfilled, (state, action) => {
        const { id, public_functions } = action.payload;
        if (state.entities[id]) {
          state.entities[id].public_functions = public_functions;
        }
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar('Public functions updated successfully!', { variant: 'success' });
      })
      .addCase(updatePublicFunctions.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(updatePublicFunctions.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updateAdminFunctions.fulfilled, (state, action) => {
        const { id, admin_functions } = action.payload;
        if (state.entities[id]) {
          state.entities[id].admin_functions = admin_functions;
        }
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar('Admin functions updated successfully!', { variant: 'success' });
      })
      .addCase(updateAdminFunctions.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(updateAdminFunctions.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updateAutocompleteSchedule.pending, (state) => {
        state.channelsLoading = true;
      })
      .addCase(updateAutocompleteSchedule.fulfilled, (state, action) => {
        const { id, autocomplete_schedule } = action.payload;
        if (state.entities[id]) {
          state.entities[id].autocomplete_schedule = autocomplete_schedule;
        }
        state.channelsLoading = false;
        state.error = null;
        enqueueSnackbar('Autocomplete schedule updated successfully!', { variant: 'success' });
      })
      .addCase(updateAutocompleteSchedule.rejected, (state, action) => {
        state.channelsLoading = false;
        state.error = action.payload as string;
        enqueueSnackbar(action.payload as string, { variant: 'error' });
      })
      .addCase(updateChannelSystemMessage.fulfilled, (state, action) => {
        const updatedChannel = action.payload;
        const index = state.channels.findIndex(channel => channel.id === updatedChannel.id);
        if (index !== -1) {
          state.channels[index] = updatedChannel;
        }
      });
  },
});

export const { upsertChannel, removeChannel } = channelsSlice.actions;
export const { selectAll: selectAllChannels, selectById: selectChannelById, selectTotal: selectTotalChannels } = channelsAdapter.getSelectors((state: RootState) => state.channels);
export default channelsSlice.reducer;

// END frontend/src/features/account/channels.slice.tsx
